var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.shown
          ? _c(
              "div",
              {
                class: `alert alert-${_vm.cssClass} flash text-center`,
                on: {
                  mouseover: _vm.cancelScheduledHide,
                  mouseout: _vm.scheduleHide,
                },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.shown = false
                      },
                    },
                  },
                  [_c("span", [_c("i", { staticClass: "fa fa-times" })])]
                ),
                _vm._v(_vm._s(_vm.text)),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }